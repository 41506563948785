export const logo = require("../assets/logo.png");
export const home = require("../assets/home.png");
export const vector1 = require("../assets/vector1.png");
export const vector2 = require("../assets/vector2.png");
export const vector3 = require("../assets/vector3.png");
export const vector4 = require("../assets/vector4.png");
export const vector5 = require("../assets/vector5.png");
export const group = require("../assets/group.png");
export const book = require("../assets/book.png");
export const vision = require("../assets/vision.png");
export const mision = require("../assets/mission.png");
export const hands = require("../assets/hands.png");
export const whyus1 = require("../assets/whyus1.png");
export const whyus2 = require("../assets/whyus2.png");
export const whyus3 = require("../assets/whyus3.png");
export const whyus4 = require("../assets/whyus4.png");
export const insta = require("../assets/insta.png");
export const linkedin = require("../assets/linkedin.png");
export const youtube = require("../assets/youtube.png");
export const facebook = require("../assets/facebook.png");
export const cross = require("../assets/cross.png");
export const tictok = require("../assets/tictok.png");
export const ICO = require("../assets/ICO.png");
export const ISO = require("../assets/ISO.png");
export const UKAS = require("../assets/UKAS.png");
export const coming = require("../assets/coming.png");
export const crown = require("../assets/crown.png");
export const logo1 = require("../assets/logo1.png");
export const builder = require("../assets/builder.png");
export const download = require("../assets/download.png");
export const zoom = require("../assets/zoom.png");
export const GBC = require("../assets/GBC.png");
export const fast = require("../assets/fast.png");
export const rating = require("../assets/rating.png");
export const greenRating = require("../assets/greenRating.png");
export const appStore = require("../assets/appStore.png");
export const googlePlay = require("../assets/googlePlay.png");
export const image001 = require("../assets/image001.png");
export const image002 = require("../assets/image002.png");
export const image003 = require("../assets/image003.png");
export const image004 = require("../assets/image004.png");
export const image005 = require("../assets/image005.png");
export const image006 = require("../assets/image006.png");
export const image007 = require("../assets/image007.png");
export const group1 = require("../assets/group1.png");
export const leftArrow = require("../assets/leftArrow.png");
export const rightArrow = require("../assets/rightArrow.png");
export const business1 = require("../assets/business1.png");
export const builder1 = require("../assets/builder1.png");
export const docusign = require("../assets/docusign.png");
export const gbg = require("../assets/gbg.png");
export const fasttrack = require("../assets/fasttrack.png");
export const zoom1 = require("../assets/zoom1.png");
export const mail = require("../assets/mail.png");
export const map = require("../assets/map.png");
export const phone = require("../assets/phone.png");
export const contactImg = require("../assets/contactImg.png");
export const CEO1 = require("../assets/CEO1.png")
export const CEO2 = require("../assets/CEO2.png")
export const Founder1 = require("../assets/Founder1.png")
export const Founder2 = require("../assets/Founder2.png")
export const CPO1 = require("../assets/CPO1.png")
export const CPO2 = require("../assets/CPO2.png")
export const Cofounder1 = require("../assets/Cofounder1.png")
export const Cofounder2 = require("../assets/Cofounder2.png")
export const placeholder = require("../assets/placeholder1.png")
export const AboutUsStepper_IMG1 = require("../assets/image_.png")
export const AboutUsStepper_IMG2 = require("../assets/image2_.png")
export const AboutUsStepper_IMG3 = require("../assets/ad_card_1_.png")
export const AboutUsStepper_IMG4 = require("../assets/ad_card_2_.png")
export const AboutUsStepper_IMG5 = require("../assets/ad_card_1_.png")
export const AccordianBackImage = require("../assets/rightArrow.png")
export const BlueRightArrow = require("../assets/rightArrow.png")
export const StudentDefaultImg = require("../assets/ad_card_1_.png")
export const svg1 = require("../assets/ad_card_1_.svg")
export const svg2 = require("../assets/ad_card_21_.svg")
export const svg3 = require("../assets/ad_card_3_.svg")
export const svg4 = require("../assets/ad_card_4_.svg")
export const localProfile = require("../assets/acount.png")
export const successImage = require("../assets/success.png");
export const failureImage = require("../assets/failure.png");
